import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Homepage from '../components/Homepage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const H3 = makeShortcode("H3");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Homepage mdxType="Homepage">
# 🏗 scaffold-eth
      <blockquote>
        <p parentName="blockquote">{`is everything you need to get started building decentralized applications powered by smart contracts`}</p>
      </blockquote>
      <hr></hr>
      <h2>{`quickstart`}</h2>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`git clone https://github.com/austintgriffith/scaffold-eth.git your-next-dapp

cd your-next-dapp
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
yarn install

`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`you might get node-gyp errors, ignore them and run:`}</p>
      </blockquote>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
yarn start

`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`in a second terminal window:`}</p>
      </blockquote>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
yarn chain

`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`in a third terminal window:`}</p>
      </blockquote>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
yarn deploy

`}</code></pre>
      <p>{`🔏 Edit your smart contract `}<inlineCode parentName="p">{`YourContract.sol`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode></p>
      <p>{`📝 Edit your frontend `}<inlineCode parentName="p">{`App.jsx`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/react-app/src`}</inlineCode></p>
      <p>{`📱 Open http://localhost:3000 to see the app`}</p>
      <p>{`📚 Keep `}<a parentName="p" {...{
          "href": "https://solidity-by-example.org"
        }}>{`solidity by example`}</a>{` handy and check out the `}<a parentName="p" {...{
          "href": "https://solidity.readthedocs.io/en/v0.6.6/units-and-global-variables.html"
        }}>{`Solidity globals and units`}</a></p>
      <blockquote>
        <p parentName="blockquote">{`With everything up your dev environment starts looking something like this:`}</p>
      </blockquote>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/91858466-768bb080-ec26-11ea-9e9b-81519f7f1c90.png",
          "alt": "image"
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`React dev server, HardHat blockchain, deploy terminal, code IDE, and frontend browser.`}</p>
      </blockquote>
      <hr></hr>
      <p>{`🔁    You can `}<inlineCode parentName="p">{`yarn run deploy`}</inlineCode>{` any time and get a fresh new contract in the frontend:`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93149199-f8fa8280-f6b2-11ea-9da7-3b26413ec8ab.gif",
          "alt": "deploy"
        }}></img></p>
      <hr></hr>
      <p>{`💵.   Each browser has an account in the top right and you can use the faucet (bottom left) to get ⛽️  testnet eth for gas:`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93150077-6c04f880-f6b5-11ea-9ee8-5c646b5b7afc.gif",
          "alt": "faucet"
        }}></img></p>
      <hr></hr>
      <p>{`🔨   Once you have funds, you can call `}<inlineCode parentName="p">{`setPurpose`}</inlineCode>{` on your contract and "write" to the `}<inlineCode parentName="p">{`purpose`}</inlineCode>{` storage:`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93229761-2d625300-f734-11ea-9036-44a75429ef0c.gif",
          "alt": "setp"
        }}></img></p>
      <hr></hr>
      <p>{`Look for the `}<a parentName="p" {...{
          "href": "https://hardhat.org"
        }}>{`HardHat`}</a>{` console.log() output in the `}<inlineCode parentName="p">{`yarn run chain`}</inlineCode>{` terminal:`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93687934-2f534b80-fa7f-11ea-84b2-c0ba99533dc2.png",
          "alt": "image"
        }}></img></p>
      <hr></hr>
      <p>{`👨‍🏫 Maybe start super simple and add a counter `}<inlineCode parentName="p">{`uint8 public count = 1;`}</inlineCode></p>
      <p>{`⬇️ Then a `}<inlineCode parentName="p">{`function dec() public {}`}</inlineCode>{` that does a `}<inlineCode parentName="p">{`count = count - 1;`}</inlineCode></p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93150263-dae25180-f6b5-11ea-94e1-b24ab2a63fa5.png",
          "alt": "image"
        }}></img></p>
      <hr></hr>
      <p>{`🔬  What happens with you subtract 1 from 0? Try it out in the app to see what happens!`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93688066-46466d80-fa80-11ea-85df-81fbafa46575.gif",
          "alt": "underflow"
        }}></img></p>
      <p>{`🚽 UNDERFLOW!`}</p>
      <p>{`🧫 You can iterate and learn as you go. Test your assumptions!`}</p>
      <hr></hr>
      <p>{`💵 Send testnet ETH between browsers or even on an `}<a parentName="p" {...{
          "href": "https://instantwallet.io"
        }}>{`instantwallet.io`}</a>{` selecting `}<inlineCode parentName="p">{`localhost`}</inlineCode>{`:`}</p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/93688154-05028d80-fa81-11ea-8643-2c447af59b5c.gif",
          "alt": "sendingaroundinstantwallet"
        }}></img></p>
      <hr></hr>
      <p>{`🔐 Global variables like `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` and `}<inlineCode parentName="p">{`msg.value`}</inlineCode>{` are cryptographically backed and can be used to make rules`}</p>
      <p>{`📝 Keep this `}<a parentName="p" {...{
          "href": "https://solidity.readthedocs.io/en/v0.7.0/cheatsheet.html?highlight=global#global-variables"
        }}>{`cheat sheet`}</a>{` handy`}</p>
      <p>{`⏳ Maybe we could use `}<inlineCode parentName="p">{`block.timestamp`}</inlineCode>{` or `}<inlineCode parentName="p">{`block.number`}</inlineCode>{` to track time in our contract`}</p>
      <p>{`🔏 Or maybe keep track of an `}<inlineCode parentName="p">{`address public owner;`}</inlineCode>{` then make a rule like `}<inlineCode parentName="p">{`require( msg.sender == owner );`}</inlineCode>{` for an important function`}</p>
      <p>{`🧾 Maybe create a smart contract that keeps track of a `}<inlineCode parentName="p">{`mapping ( address => uint256 ) public balance;`}</inlineCode></p>
      <p>{`🏦 It could be like a decentralized bank that you `}<inlineCode parentName="p">{`function deposit() public payable {}`}</inlineCode>{` and `}<inlineCode parentName="p">{`withdraw()`}</inlineCode></p>
      <p>{`📟 Events are really handy for signaling to the frontend. `}<a parentName="p" {...{
          "href": "https://solidity-by-example.org/0.6/events/"
        }}>{`Read more about events here.`}</a></p>
      <p>{`📲 Spend some time in `}<inlineCode parentName="p">{`App.jsx`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/react-app/src`}</inlineCode>{` and learn about the 🛰 `}<a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth#-web3-providers"
        }}>{`Providers`}</a></p>
      <p>{`⚠️ Big numbers are stored as objects: `}<inlineCode parentName="p">{`formatEther`}</inlineCode>{` and `}<inlineCode parentName="p">{`parseEther`}</inlineCode>{` (ethers.js) will help with WEI->ETH and ETH->WEI.`}</p>
      <p>{`🧳 The single page (searchable) `}<a parentName="p" {...{
          "href": "https://docs.ethers.io/v5/single-page/"
        }}>{`ethers.js docs`}</a>{` are pretty great too.`}</p>
      <p>{`🐜 The UI framework `}<inlineCode parentName="p">{`Ant Design`}</inlineCode>{` has a `}<a parentName="p" {...{
          "href": "https://ant.design/components/overview/"
        }}>{`bunch of great components`}</a>{`.`}</p>
      <p>{`📃 Check the console log for your app to see some extra output from hooks like `}<inlineCode parentName="p">{`useContractReader`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEventListener`}</inlineCode>{`.`}</p>
      <p>{`🏗 You'll notice the `}<inlineCode parentName="p">{`<Contract />`}</inlineCode>{` component that displays the dynamic form as scaffolding for interacting with your contract.`}</p>
      <p>{`🔲 Try making a `}<inlineCode parentName="p">{`<Button/>`}</inlineCode>{` that calls `}<inlineCode parentName="p">{`writeContracts.YourContract.setPurpose("👋 Hello World")`}</inlineCode>{` to explore how your UI might work...`}</p>
      <p>{`💬 Wrap the call to `}<inlineCode parentName="p">{`writeContracts`}</inlineCode>{` with a `}<inlineCode parentName="p">{`tx()`}</inlineCode>{` helper that uses BlockNative's `}<a parentName="p" {...{
          "href": "https://www.blocknative.com/notify"
        }}>{`Notify.js`}</a>{`.`}</p>
      <p>{`🧬 Next learn about `}<a parentName="p" {...{
          "href": "https://solidity-by-example.org/0.6/structs/"
        }}>{`structs`}</a>{` in Solidity.`}</p>
      <p>{`🗳 Maybe an make an array `}<inlineCode parentName="p">{`YourStructName[] public proposals;`}</inlineCode>{` that could call be voted on with `}<inlineCode parentName="p">{`function vote() public {}`}</inlineCode></p>
      <p>{`🔭 Your dev environment is perfect for `}<em parentName="p">{`testing assumptions`}</em>{` and learning by prototyping.`}</p>
      <p>{`📝 Next learn about the `}<a parentName="p" {...{
          "href": "https://solidity-by-example.org/0.6/fallback/"
        }}>{`fallback function`}</a></p>
      <p>{`💸 Maybe add a `}<inlineCode parentName="p">{`receive() external payable {}`}</inlineCode>{` so your contract will accept ETH?`}</p>
      <p>{`🚁 OH! Programming decentralized money! 😎 So rad!`}</p>
      <p>{`🛰 Ready to deploy to a testnet? Change the `}<inlineCode parentName="p">{`defaultNetwork`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/hardhat.config.js`}</inlineCode></p>
      <p>{`🔐 Generate a deploy account with `}<inlineCode parentName="p">{`yarn generate`}</inlineCode>{` and view it with `}<inlineCode parentName="p">{`yarn account`}</inlineCode></p>
      <p>{`🔑 Create wallet links to your app with `}<inlineCode parentName="p">{`yarn wallet`}</inlineCode>{` and `}<inlineCode parentName="p">{`yarn fundedwallet`}</inlineCode></p>
      <p>{`👩‍🎓 You can "graduate" from 🏗 scaffold-eth and start using 👷 `}<a parentName="p" {...{
          "href": "https://hardhat.org/"
        }}>{`HardHat`}</a>{` and 📦 `}<a parentName="p" {...{
          "href": "https://github.com/PaulRBerg/create-eth-app"
        }}>{`create-eth-app`}</a>{` "standalone"`}</p>
      <p>{`( You will probably want to take some of the 🔗 `}<a parentName="p" {...{
          "href": "#-hooks"
        }}>{`hooks`}</a>{`, 🎛 `}<a parentName="p" {...{
          "href": "#-components"
        }}>{`components`}</a>{` with you from 🏗 scaffold-eth so we started 🖇 `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/eth-hooks"
        }}>{`eth-hooks`}</a>{` )`}</p>
      <p>{`🚀 Good luck!`}</p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth#-smart-contract-sandbox"
        }}><H3 mdxType="H3">{`⏱ Quickstart: 🔬 Smart Contract Sandbox`}</H3></a></p>
      <p>{`Learn how to `}<a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth#-smart-contract-sandbox"
        }}>{`quickly iterate on a smart contract app`}</a>{` using the `}<b>{`\\<Contract /`}{`>`}</b>{` component.`}</p>
      <hr></hr>
      <p>{`Join the telegram `}<a parentName="p" {...{
          "href": "https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA"
        }}>{`support chat 💬`}</a>{`  to ask questions and find others building with 🏗 scaffold-eth!`}</p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=ShJZf5lsXiM&feature=youtu.be&t=19"
        }}><img parentName="a" {...{
            "src": "https://user-images.githubusercontent.com/2653167/88085723-7ab2b180-cb43-11ea-832d-8db6efcbdc02.png",
            "alt": "splash"
          }}></img></a></p>
      <hr></hr>
      <p>{`🎥.  `}<a parentName="p" {...{
          "href": "https://youtu.be/_yRX8Qi75OE?t=289"
        }}>{`Watch the long form 🏗 scaffold-eth introduction on youtube for the EEA`}</a>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/_yRX8Qi75OE?t=289"
        }}><img parentName="a" {...{
            "src": "https://user-images.githubusercontent.com/2653167/93264124-e9874200-f763-11ea-9519-94736b95b2d1.png",
            "alt": "image"
          }}></img></a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/programming-decentralized-money-300bacec3a4f"
        }}><H3 mdxType="H3">{`Tutorial 1: 🛠 Programming Decentralized Money`}</H3></a></p>
      <p>{`Learn the basics of 🏗 `}<b>{`scaffold-eth`}</b>{` and building on `}<b>{`Ethereum`}</b>{`. 👷‍♂️ HardHat, 📦 create-eth-app, 🔥 hot reloading smart contracts, 🛰 providers, 🔗 hooks, 🎛 components, and building a decentralized application.
`}<a parentName="p" {...{
          "href": "https://youtu.be/7rq3TPL-tgI"
        }}>{`🎥 Guided Tutorial`}</a></p>
      <hr></hr>
      <H3 mdxType="H3">Tutorial 2: 🏵 The Token</H3>
      <p>{`Learn about tokens. `}{`[coming soon]`}{` What is a token? Why is it cool? How can I deploy one? Exotic mechanisms? (todo)`}</p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/%EF%B8%8F-minimum-viable-exchange-d84f30bd0c90"
        }}><H3 mdxType="H3">{`Tutorial 3: ⚖️ Minimum Viable Decentralized Exchange`}</H3></a></p>
      <p>{`Learn the basics of Automated Market Makers like 🦄 Uniswap. Learn how 💰Reserves affect the 📉 price, ⚖️ trading, and 💦 slippage from low liqudity.`}</p>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/eP5w6Ger1EQ"
        }}>{`🏃‍♀️ SpeedRun  📹`}</a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/tl-dr-scaffold-eth-ipfs-20fa35b11c35"
        }}><H3 mdxType="H3">{`Tutorial 4: 🚀 Connecting ETH to IPFS`}</H3></a></p>
      <p>{`Build a simple IPFS application in 🏗 `}<b>{`scaffold-eth`}</b>{` to learn more about distributed file storage and content addressing.
`}<a parentName="p" {...{
          "href": "https://youtu.be/vqrLr5eOjLo?t=342"
        }}>{`🎥 Live Tutorial`}</a></p>
      <hr></hr>
      <H3 mdxType="H3">Tutorial 5: ⛽️GSN and Meta Transactions</H3>
      <p>{`Learn about to provide your users with better UX by abstracting away gas fees and blockchain mechanics.  (todo)`}</p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/decentralized-deployment-7d975c9d5016"
        }}><H3 mdxType="H3">{`Tutorial 6: 🛰 Decentralized Deployment`}</H3></a></p>
      <p>{`Learn how to deploy your smart contract to a production blockchain. Then deploy your applicaton to Surge, S3, and IPFS. Finally, register an ENS and point it at the decentralized content!  `}<a parentName="p" {...{
          "href": "https://youtu.be/vqrLr5eOjLo?t=1350"
        }}>{`🎥 Live Tutorial`}</a></p>
      <hr></hr>
      <p>{`  📡 Using The Graph with 🏗 scaffold-eth`}</p>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/ODSTP5OjG2M"
        }}><img parentName="a" {...{
            "src": "https://user-images.githubusercontent.com/2653167/96306619-41aca080-0fbd-11eb-8882-8f6c115863eb.png",
            "alt": "image"
          }}></img>
        </a></p>
      <hr></hr>
      <h1>built with 🏗 scaffold-eth:</h1>
      <p><a parentName="p" {...{
          "href": "https://nifty.ink"
        }}><H3 mdxType="H3">{`👩‍🎨 Nifty Ink`}</H3></a></p>
      <p>{`Paintings come to life as you "ink" new creations and trade them on Ethereum. A deep dive into 🖼 NFTs, 🐳 OpenSea, 🖍 react-canvas-draw, 🎨 react-color, and 🛬 onboarding user experience.`}</p>
      <p>{`🏃‍♂️ SpeedRun 📹 (TODO)`}</p>
      <p><a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth/tree/nifty-ink-dev"
        }}>{`💾 Source Code `}</a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://instantwallet.io"
        }}><H3 mdxType="H3">{`🧙‍♂️ Instant Wallet`}</H3></a></p>
      <p>{`An instant wallet running on xDAI insired by `}<a parentName="p" {...{
          "href": "https://xdai.io"
        }}>{`xdai.io`}</a>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth/tree/instantwallet-dev-session"
        }}>{`💾 Source Code `}</a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/personal-token-voting-73b44a598d8e"
        }}><H3 mdxType="H3">{`🗳 Personal Token Voting`}</H3></a></p>
      <p>{`Poll your holders! Build an example emoji voting system with 🏗 `}<b>{`scaffold-eth`}</b>{`. 🔏 Cryptographically signed votes but tracked off-chain with 📡 Zapier and 📑 Google Sheets.`}</p>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/Q5zgxcQtwWI"
        }}>{`🏃‍♂️ SpeedRun 📹 `}</a></p>
      <p><a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth/tree/emoji-vote-dev"
        }}>{`💾 Source Code `}</a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://xmoon.exchange/"
        }}><H3 mdxType="H3">{`🌒 xmoon.exchange`}</H3></a></p>
      <p>{`Exchange Reddit MOONs for ETH or DAI through xDAI. Learn about different 🛰 providers and how 🌉 bridges can connect different chains with different security models.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=_ikHSyThDiA"
        }}>{`🏃‍♂️ SpeedRun 📹 `}</a></p>
      <p><a parentName="p" {...{
          "href": "https://github.com/austintgriffith/scaffold-eth/tree/xmoon-dev"
        }}>{`💾 Source Code `}</a></p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://obituary.space/"
        }}><H3 mdxType="H3">{`Obituary.space`}</H3></a></p>
      <p>{`Remember someone permanently on the blockchain. Write an obituary and upload a photo of a person and their memory will be encoded on the blockchain, forever.`}</p>
      <hr></hr>
      <p>{`^^^ ⛏ `}<b>{`PR`}</b>{` your 🏗 scaffold-eth project in above!!! ^^^`}</p>
      <hr></hr>
      <p><a parentName="p" {...{
          "href": "https://medium.com/@austin_48503/building-on-ethereum-in-2020-dca52eda5f00"
        }}><h5>{`🧫 Building on Ethereum in 2020 (research)`}</h5>{`  `}</a></p>
      <hr></hr>
      <h2>{`⏱ Original Quickstart with TODO List:`}</h2>
      <p>{`First, you'll need `}<a parentName="p" {...{
          "href": "https://nodejs.org/en/download/"
        }}>{`NodeJS>=10`}</a>{` plus `}<a parentName="p" {...{
          "href": "https://classic.yarnpkg.com/en/docs/install/"
        }}>{`Yarn`}</a>{` and `}<a parentName="p" {...{
          "href": "https://git-scm.com/downloads"
        }}>{`Git`}</a>{` installed.`}</p>
      <p>{`💾 `}<b>{`install`}</b>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`git clone https://github.com/austintgriffith/scaffold-eth.git rad-new-dapp

cd rad-new-dapp

git checkout quickstart

yarn install
`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`⌚️ This will take some time. How about a quick tour of the file structure with your favorite code editor?`}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`💡 Sometimes the install throws errors like "node-gyp", try the next step even if you see problems.`}<br />{`
(You can also `}<a parentName="p" {...{
            "href": "https://developer.apple.com/download/more/"
          }}>{`download the Apple command line tools`}</a>{` to fix the warning.)`}</p>
      </blockquote>
      <hr></hr>
      <p>{`🎛 `}<b>{`frontend`}</b></p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn start
`}</code></pre>
      <p>{`📝 Edit your frontend `}<inlineCode parentName="p">{`App.jsx`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/react-app/src`}</inlineCode>{` and open http://localhost:3000`}</p>
      <hr></hr>
      <p>{`⛓ `}<b>{`blockchain`}</b></p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run chain
`}</code></pre>
      <p><strong parentName="p">{`Note`}</strong>{`: You'll need to run this command in a new terminal window`}</p>
      <blockquote>
        <p parentName="blockquote">{`🛠 `}<a parentName="p" {...{
            "href": "https://eth.build/build#1a21b864c6bcdb901070b64965fae825cdfc11b1915d74f058f00b114a8c129a"
          }}>{`Use this eth.build`}</a>{` to double-check your local chain and account balances`}</p>
      </blockquote>
      <hr></hr>
      <p>{`⚙️ Compile your contracts:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run compile
`}</code></pre>
      <p>{`🚢 Deploy your contracts to the frontend:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run deploy
`}</code></pre>
      <p>{`🔍 `}<em parentName="p">{`Watch`}</em>{` for changes then compile, deploy, and hot reload the frontend:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run watch
`}</code></pre>
      <hr></hr>
      <p>{`🔥 Your dapp hot reloads as you build your smart contracts and frontend together 🔥`}</p>
      <hr></hr>
      <p>{`📝 Edit your smart contract `}<inlineCode parentName="p">{`SmartContractWallet.sol`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode></p>
      <blockquote>
        <p parentName="blockquote">{`🤡 There is a spelling error in `}<inlineCode parentName="p">{`packages/hardhat/contracts/SmartContractWallet.sol`}</inlineCode>{`! `}<br /><br />{`🤔 Can you fix it and deploy the contract locally?`}</p>
      </blockquote>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/81483187-8146b380-91f9-11ea-80f0-3a8e1e3225dd.png",
          "alt": "Deployed Contract"
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`☢️ `}<strong parentName="p">{`Warning`}</strong>{`: It is very important that you find `}<inlineCode parentName="p">{`SmartContractWallet.sol`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode>{` because there are other contract folders and it can get confusing.`}</p>
      </blockquote>
      <p>{`🔬Test your contracts by editing `}<inlineCode parentName="p">{`myTest.js`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run test
`}</code></pre>
      <p>{`🗝 List your local accounts:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run accounts
`}</code></pre>
      <p>{`💰 Check account balance:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run balance **YOUR-ADDRESS**
`}</code></pre>
      <p>{`💸 Send ETH:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yarn run send --from 0 --amount 0.5 --to **YOUR-ADDRESS**
`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`🔧 Configure 👷`}<a parentName="p" {...{
            "href": "https://hardhat.org/config/"
          }}>{`HardHat`}</a>{` by editing `}<inlineCode parentName="p">{`hardhat.config.js`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat`}</inlineCode></p>
      </blockquote>
      <hr></hr>
      <p>{`✨ The `}<a parentName="p" {...{
          "href": "https://hardhat.org/hardhat-network/"
        }}>{`HardHat network`}</a>{` provides `}<em parentName="p">{`stack traces`}</em>{` and `}<em parentName="p">{`console.log`}</em>{` debugging for our contracts ✨`}</p>
      <hr></hr>
      <h2>{`🏃‍♂️ Speedrun (🎥 7 min):`}</h2>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/eUAc2FtC0_s"
        }}><img parentName="a" {...{
            "src": "https://user-images.githubusercontent.com/2653167/80823035-13ffa680-8b99-11ea-880e-ae37b752ca59.png",
            "alt": "speedrun"
          }}></img></a></p>
      <hr></hr>
      <h2>{`🔬 Smart Contract Sandbox:`}</h2>
      <p>{`💾 `}<b>{`install`}</b>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`git clone https://github.com/austintgriffith/scaffold-eth.git smart-contract-sandbox

cd smart-contract-sandbox

yarn install
`}</code></pre>
      <p>{`⚙️ `}<b>{`start`}</b></p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`#run in original terminal window:
yarn start
#run in terminal window 2:
yarn run chain
#run in terminal window 3:
yarn run deploy
`}</code></pre>
      <p>{`🔏 Edit or rename your smart contract `}<inlineCode parentName="p">{`YourContract.sol`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode></p>
      <p>{`📝 Edit your frontend `}<inlineCode parentName="p">{`App.jsx`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/react-app/src`}</inlineCode></p>
      <p>{`📱 Open http://localhost:3000 to see the app`}</p>
      <p>{`☢️ Make sure are running your local chain `}<inlineCode parentName="p">{`yarn run chain`}</inlineCode>{` and your contract is deployed with `}<inlineCode parentName="p">{`yarn run deploy`}</inlineCode></p>
      <p>{`🔥 Try `}<inlineCode parentName="p">{`yarn run watch`}</inlineCode>{` and as you change your Solidity, your frontend `}<b>{`\\<Contract/`}{`>`}</b>{` will hot reload to give you access to new variables and functions:`}</p>
      <p>{`📽 `}<a parentName="p" {...{
          "href": "https://youtu.be/ShJZf5lsXiM?t=34"
        }}>{`Video Guide`}</a></p>
      <p>{`📚 RTFM: Check out `}<a parentName="p" {...{
          "href": "https://solidity-by-example.org"
        }}>{`solidity by example`}</a>{` and check out the `}<a parentName="p" {...{
          "href": "https://solidity.readthedocs.io/en/v0.4.24/units-and-global-variables.html"
        }}>{`Solidity globals and units`}</a></p>
      <p>{`🚀 Good luck, and go get 'em!`}</p>
      <hr></hr>
      <h2>{`🔏 Web3 Providers:`}</h2>
      <p>{`The frontend has three different providers that provide different levels of access to different chains:`}</p>
      <p><inlineCode parentName="p">{`mainnetProvider`}</inlineCode>{`: (read only) `}<a parentName="p" {...{
          "href": "https://infura.io/"
        }}>{`Infura`}</a>{` connection to main `}<a parentName="p" {...{
          "href": "https://ethereum.org/developers/"
        }}>{`Ethereum`}</a>{` network (and contracts already deployed like `}<a parentName="p" {...{
          "href": "https://etherscan.io/address/0x6b175474e89094c44da98b954eedeac495271d0f#code"
        }}>{`DAI`}</a>{` or `}<a parentName="p" {...{
          "href": "https://etherscan.io/address/0x2a1530c4c41db0b0b2bb646cb5eb1a67b7158667"
        }}>{`Uniswap`}</a>{`).`}</p>
      <p><inlineCode parentName="p">{`localProvider`}</inlineCode>{`: local `}<a parentName="p" {...{
          "href": "https://hardhat.org"
        }}>{`HardHat`}</a>{` accounts, used to read from `}<em parentName="p">{`your`}</em>{` contracts (`}<inlineCode parentName="p">{`.env`}</inlineCode>{` file points you at testnet or mainnet)`}</p>
      <p><inlineCode parentName="p">{`injectedProvider`}</inlineCode>{`: your personal `}<a parentName="p" {...{
          "href": "https://metamask.io/download.html"
        }}>{`MetaMask`}</a>{`, `}<a parentName="p" {...{
          "href": "https://walletconnect.org/apps"
        }}>{`WalletConnect`}</a>{` via `}<a parentName="p" {...{
          "href": "https://www.argent.xyz/"
        }}>{`Argent`}</a>{`, or other injected wallet (generates `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/burner-provider"
        }}>{`burner-provider`}</a>{` on page load)`}</p>
      <hr></hr>
      <p>{`🐜 `}<a parentName="p" {...{
          "href": "https://ant.design/components/button/"
        }}>{`Ant.design`}</a>{` is the UI library with components like the `}<a parentName="p" {...{
          "href": "https://ant.design/components/grid/"
        }}>{`grids`}</a>{`, `}<a parentName="p" {...{
          "href": "https://ant.design/components/menu/"
        }}>{`menus`}</a>{`, `}<a parentName="p" {...{
          "href": "https://ant.design/components/date-picker/"
        }}>{`dates`}</a>{`, `}<a parentName="p" {...{
          "href": "https://ant.design/components/time-picker/"
        }}>{`times`}</a>{`, `}<a parentName="p" {...{
          "href": "https://ant.design/components/button/"
        }}>{`buttons`}</a>{`, etc.`}</p>
      <hr></hr>
      <h2>{`⛑ Helpers:`}</h2>
      <p><inlineCode parentName="p">{`Transactor`}</inlineCode>{`: The transactor returns a `}<inlineCode parentName="p">{`tx()`}</inlineCode>{` function to make running and tracking transactions as simple and standardized as possible. We will bring in `}<a parentName="p" {...{
          "href": "https://www.blocknative.com/notify"
        }}>{`BlockNative's Notify`}</a>{` library to track our testnet and mainnet transactions.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const tx = Transactor(props.injectedProvider, props.gasPrice);
`}</code></pre>
      <p>{`Then you can use the `}<inlineCode parentName="p">{`tx()`}</inlineCode>{` function to send funds and write to your smart contracts:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`tx({
  to: readContracts[contractName].address,
  value: parseEther("0.001"),
});
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`tx(writeContracts["SmartContractWallet"].updateOwner(newOwner));
`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`☢️ `}<strong parentName="p">{`Warning`}</strong>{`: You will need to update the configuration for `}<inlineCode parentName="p">{`react-app/src/helpers/Transactor.js`}</inlineCode>{` to use `}<em parentName="p">{`your`}</em>{` `}<a parentName="p" {...{
            "href": "https://www.blocknative.com/notify"
          }}>{`BlockNative dappId`}</a></p>
      </blockquote>
      <hr></hr>
      <h2>{`🖇 Hooks:`}</h2>
      <p>{`Commonly used Ethereum hooks located in `}<inlineCode parentName="p">{`packages/react-app/src/`}</inlineCode>{`:`}</p>
      <p><inlineCode parentName="p">{`usePoller(fn, delay)`}</inlineCode>{`: runs a function on app load and then on a custom interval`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`usePoller(() => {
  //do something cool at start and then every three seconds
}, 3000);
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useBalance(address, provider, [pollTime])`}</inlineCode>{`: poll for the balance of an address from a provider`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const localBalance = useBalance(address, localProvider);
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useBlockNumber(provider,[pollTime])`}</inlineCode>{`: get current block number from a provider`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const blockNumber = useBlockNumber(props.provider);
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useGasPrice([speed])`}</inlineCode>{`: gets current "fast" price from `}<a parentName="p" {...{
          "href": "https://ethgasstation.info"
        }}>{`ethgasstation`}</a></p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const gasPrice = useGasPrice();
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useExchangePrice(mainnetProvider, [pollTime])`}</inlineCode>{`: gets current price of Ethereum on the Uniswap exchange`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const price = useExchangePrice(mainnetProvider);
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useContractLoader(provider)`}</inlineCode>{`: loads your smart contract interface`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const readContracts = useContractLoader(localProvider);
const writeContracts = useContractLoader(injectedProvider);
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useContractReader(contracts, contractName, variableName, [pollTime])`}</inlineCode>{`: reads a variable from your contract and keeps it in the state`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const title = useContractReader(props.readContracts, contractName, "title");
const owner = useContractReader(props.readContracts, contractName, "owner");
`}</code></pre>
      <br />
      <p><inlineCode parentName="p">{`useEventListener(contracts, contractName, eventName, [provider], [startBlock])`}</inlineCode>{`: listens for events from a smart contract and keeps them in the state`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`const ownerUpdates = useEventListener(
  readContracts,
  contractName,
  "UpdateOwner",
  props.localProvider,
  1
);
`}</code></pre>
      <hr></hr>
      <h2>{`📦 Components:`}</h2>
      <p>{`Your commonly used React Ethereum components located in `}<inlineCode parentName="p">{`packages/react-app/src/`}</inlineCode>{`:`}</p>
      <br />
      <p>{`📬 `}<inlineCode parentName="p">{`<Address />`}</inlineCode>{`: A simple display for an Ethereum address that uses a `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/ethereum-blockies"
        }}>{`Blockie`}</a>{`, lets you copy, and links to `}<a parentName="p" {...{
          "href": "https://etherscan.io/"
        }}>{`Etherescan`}</a>{`.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`  <Address value={address} />
  <Address value={address} size="short" />
  <Address value={address} size="long" blockexplorer="https://blockscout.com/poa/xdai/address/"/>
  <Address value={address} ensProvider={mainnetProvider}/>
`}</code></pre>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/80522487-e375fd80-8949-11ea-84fd-0de3eab5cd03.gif",
          "alt": "ensaddress"
        }}></img></p>
      <br />
      <p>{`🖋 `}<inlineCode parentName="p">{`<AddressInput />`}</inlineCode>{`: An input box you control with useState for an Ethereum address that uses a `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/ethereum-blockies"
        }}>{`Blockie`}</a>{` and ENS lookup/display.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`  const [ address, setAddress ] = useState("")
  <AddressInput
    value={address}
    ensProvider={props.ensProvider}
    onChange={(address)=>{
      setAddress(address)
    }}
  />
`}</code></pre>
      <p>{`TODO GIF`}</p>
      <br />
      <p>{`💵 `}<inlineCode parentName="p">{`<Balance />`}</inlineCode>{`: Displays the balance of an address in either dollars or decimal.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Balance
  address={address}
  provider={injectedProvider}
  dollarMultiplier={price}
/>
`}</code></pre>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/80522919-86c71280-894a-11ea-8f61-70bac7a72106.gif",
          "alt": "balance"
        }}></img></p>
      <br />
      <br />
      <p>{`👤 `}<inlineCode parentName="p">{`<Account />`}</inlineCode>{`: Allows your users to start with an Ethereum address on page load but upgrade to a more secure, injected provider, using `}<a parentName="p" {...{
          "href": "https://web3modal.com/"
        }}>{`Web3Modal`}</a>{`. It will track your `}<inlineCode parentName="p">{`address`}</inlineCode>{` and `}<inlineCode parentName="p">{`localProvider`}</inlineCode>{` in your app's state:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const [address, setAddress] = useState();
const [injectedProvider, setInjectedProvider] = useState();
const price = useExchangePrice(mainnetProvider);
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Account
  address={address}
  setAddress={setAddress}
  localProvider={localProvider}
  injectedProvider={injectedProvider}
  setInjectedProvider={setInjectedProvider}
  dollarMultiplier={price}
/>
`}</code></pre>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/80527048-fdffa500-8950-11ea-9a0f-576be87e4368.gif",
          "alt": "account"
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`💡 `}<strong parentName="p">{`Notice`}</strong>{`: the `}<inlineCode parentName="p">{`<Account />`}</inlineCode>{` component will call `}<inlineCode parentName="p">{`setAddress`}</inlineCode>{` and `}<inlineCode parentName="p">{`setInjectedProvider`}</inlineCode>{` for you.`}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`☢️ `}<strong parentName="p">{`Warning`}</strong>{`: You will need to update the configuration for `}<inlineCode parentName="p">{`Web3Modal`}</inlineCode>{` to use `}<em parentName="p">{`your`}</em>{` `}<a parentName="p" {...{
            "href": "https://infura.io/login"
          }}>{`Infura Id`}</a></p>
      </blockquote>
      <br />
      <br />
      <p>{`📡 `}<inlineCode parentName="p">{`<Provider />`}</inlineCode>{`: You can choose to display the provider connection status to your users with:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Provider name={"mainnet"} provider={mainnetProvider} />
<Provider name={"local"} provider={localProvider} />
<Provider name={"injected"} provider={injectedProvider} />
`}</code></pre>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/80524033-3781e180-894c-11ea-8965-98eb5e2e5e71.gif",
          "alt": "providere"
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`💡 `}<strong parentName="p">{`Notice`}</strong>{`: you will need to check the network id of your `}<inlineCode parentName="p">{`injectedProvider`}</inlineCode>{` compared to your `}<inlineCode parentName="p">{`localProvider`}</inlineCode>{` or `}<inlineCode parentName="p">{`mainnetProvider`}</inlineCode>{` and alert your users if they are on the wrong network!`}</p>
      </blockquote>
      <hr></hr>
      <h2>{`📄 Smart Contract Wallet:`}</h2>
      <p>{`📝 Edit your smart contract `}<inlineCode parentName="p">{`SmartContractWallet.sol`}</inlineCode>{` in `}<inlineCode parentName="p">{`packages/hardhat/contracts`}</inlineCode></p>
      <p>{`📝 Then edit the `}<inlineCode parentName="p">{`SmartContractWallet.js`}</inlineCode>{` React component in `}<inlineCode parentName="p">{`packages/react-app/src`}</inlineCode></p>
      <p>{`▶️ Run `}<inlineCode parentName="p">{`yarn run compile`}</inlineCode>{` and `}<inlineCode parentName="p">{`yarn run deploy`}</inlineCode>{` or just `}<inlineCode parentName="p">{`yarn run watch`}</inlineCode></p>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/2653167/80741479-ece0a080-8ad6-11ea-9850-f576f7be2b85.gif",
          "alt": "smortcontractwallet"
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`🛠 Run `}<a parentName="p" {...{
            "href": "https://eth.build/build#32f1ecd6d90518387f2f34c47176bf67fdf55c855bff39f85de08d76696b850f"
          }}>{`this eth.build`}</a>{` with your contract address to ask it who its owner is.`}</p>
      </blockquote>
      <hr></hr>
      <p>{`📚 OpenZeppelin Contracts -- TODO`}</p>
      <p>{`You can import any of the `}<a parentName="p" {...{
          "href": "https://github.com/OpenZeppelin/openzeppelin-contracts/tree/master/contracts"
        }}>{`OpenZeppelin contracts`}</a>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import "@openzeppelin/contracts/token/ERC20/IERC20.sol";
`}</code></pre>
      <hr></hr>
      <p>{`🛰 The Graph -- TODO`}</p>
      <p>{`⛽️ GSN -- TODO`}</p>
      <hr></hr>
      <h2>{`📤 Save to your Git`}</h2>
      <p>{`Create a new repo with the same name as this project and then:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`git remote add origin https://github.com/**YOUR_GITHUB_USERNAME**/**YOUR_COOL_PROJECT_NAME**.git
git push -u origin master
`}</code></pre>
      <hr></hr>
      <h2>{`🛳 Ship it!`}</h2>
      <p>{`You can deploy your static site and your dapp can go live:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
yarn run build

# ship it!

yarn run surge

OR

yarn run s3

OR

yarn run ipfs
`}</code></pre>
    </Homepage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      